export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_RECEIVE = "GET_USER_RECEIVE";
export const GET_USER_ERROR = "GET_USER_ERROR";

export const SET_LOCALE = "SET_LOCALE";
export const SET_LOCALE_DATA = "SET_LOCALE_DATA";
export const SHOW_MODAL = "SHOW_MODAL";

export const VALIDATE_PHONE_REQUEST = "VALIDATE_PHONE_REQUEST";
export const VALIDATE_PHONE_RECEIVE = "VALIDATE_PHONE_RECEIVE";
export const VALIDATE_PHONE_ERROR = "VALIDATE_PHONE_ERROR";

export const CHECK_LEAD_EXISTS = "CHECK_LEAD_EXISTS";
export const CHECK_LEAD_EXISTS_ERROR = "CHECK_LEAD_EXISTS_ERROR";

export const GET_USER_DATA = "GET_USER_DATA";
export const GET_USER_DATA_ERROR = "GET_USER_DATA_ERROR";

export const CREATE_LEAD = "CREATE_LEAD";
export const CREATE_LEAD_ERROR = "CREATE_LEAD_ERROR";

export const SENT_TEXT_BACK = "SENT_TEXT_BACK";
export const SENT_TEXT_BACK_ERROR = "SENT_TEXT_BACK_ERROR";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_RECEIVE = "UPDATE_USER_RECEIVE";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const HEALTH_CHECK = "HEALTH_CHECK";
export const HEALTH_CHECK_SUCCESS = "HEALTH_CHECK_SUCCESS";
export const HEALTH_CHECK_ERROR = "HEALTH_CHECK_ERROR";
