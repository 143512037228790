import { healthCheck } from "../../redux/actions";
import history from "../../history";

export default (store) => (next) => (action) => {
  if (action.error) {
    switch (action.error.status) {
      case 500:
      case 501:
      case 502:
      case 503:
      case 504:
      case 505:
      case 506:
      case 507:
      case 508:
      case 510:
      case 511:
        store
          .dispatch(healthCheck())
          .then((resp) => {
            history.push("/maintenance");
            return resp;
          })
          .catch(() => {
            history.push("/healthcheck");
          });
        break;
      default:
        store.dispatch(healthCheck()).catch(() => {
          history.push("/healthcheck");
        });
    }
  }

  const result = next(action);

  return result;
};
