import {
  REACT_APP_LOCALES,
  REACT_APP_PHONE_MASK,
  REACT_APP_PHONE_TEXT,
  REACT_APP_PHONE,
  REACT_APP_LANDING_GUID,
  REACT_APP_FLAGS,
  REACT_APP_FB_LINKS,
  COUNTRIES,
} from "./../config";

export const localesData = REACT_APP_LOCALES
  ? REACT_APP_LOCALES
    .split(',')
    .reduce((res, item, key) => {
      return {
        ...res,
        ...{
          [item]: {
            country: COUNTRIES.find((c) => c.countryCode === item).value,
            ...(REACT_APP_PHONE_TEXT && { phoneText: REACT_APP_PHONE_TEXT.split(',')[key] }),
            ...(REACT_APP_PHONE_MASK && { phoneMask: REACT_APP_PHONE_MASK.split(',')[key] }),
            ...(REACT_APP_PHONE && { phone: REACT_APP_PHONE.split(',')[key] }),
            ...(REACT_APP_LANDING_GUID && { landingGuid: REACT_APP_LANDING_GUID.split(',')[key] }),
            ...(REACT_APP_FLAGS && { flag: REACT_APP_FLAGS.split(',')[key] }),
            ...(REACT_APP_FB_LINKS && { fbLink: REACT_APP_FB_LINKS.split(',')[key] })
          }
        }
      }
    }, {})
  : {};

